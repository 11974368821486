import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog, openDialog } from "../../redux/Slices/dialogSlice";
import { RootState } from "../../redux/Store/store";
import CustomButton from "./CustomButton";
import { setCount, setIsPopUp } from "../../redux/Slices/startFullTestSlice";
import "katex/dist/katex.min.css";
import MathsModuleDirections from "./MathsModuleDirections";
import Timer from "./Timer";
import { setAnswers } from "../../redux/Slices/resultSlice";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import PopUp from "./PopUp";
import { useNavigate } from "react-router-dom";
import { getItem, removeItem, setItem } from "../../utils/token";
import RenderText from "./RenderText";
import QuestionsOverView from "./QuestionsOverView";
import { FaCalculator } from "react-icons/fa";
import referenceImage from "../../assets/Images/util-icon.png";

interface Answer {
  isActive: boolean;
  answerOption: string;
  isCorrectAnswer: boolean;
  correctAnswer: string;
  answerPlaceHolder: string;
}
interface Answers {
  qId: string;
  userAnswer: string | undefined;
  userOption: string;
}

interface ReadingModuleData {
  _id: string;
  isActive: boolean;
  description: string;
  question: string;
  processedQuestion: string;
  explanation: string;
  moduleId: string;
  sectionId: string;
  answers: Answer[];
  questionTypeId: string;
  more: any;
  additionalProps: any;
}

interface CommonLayout {
  heading: () => React.ReactNode;
  timer: () => React.ReactNode;
  exit: () => React.ReactNode;
  children: () => React.ReactNode;
  textName: () => React.ReactNode;
  next: () => React.ReactNode;
  questionsList: ReadingModuleData[];
  moduleName: string;
  userAnswers: Answers[];
}

interface Answer {
  isActive: boolean;
  answerOption: string;
  isCorrectAnswer: boolean;
  correctAnswer: string;
  answerPlaceHolder: string;
}

interface FormAnswerSet {
  answerOption: string;
  answerPlaceHolder: string;
  isActive: boolean;
  isCorrectAnswer: boolean;
}

const CommonTestModuleLayout = ({
  heading,
  timer,
  exit,
  children,
  textName,
  next,
  questionsList,
  moduleName,
  userAnswers,
}: CommonLayout) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { count, isPopUp,sidebarCollapse } = useSelector((state: RootState) => ({
    count: state.root.startFullTest.count,
    isPopUp: state.root.startFullTest.isPopUp,
    sidebarCollapse: state.root.startFullTest.sidebarCollapse
  }));

  const memoizedUserAnswers = useMemo(() => userAnswers, [userAnswers]);
  const saveExitDialog = () => {
    dispatch(openDialog("saveExitDialogue"));
  };
  const renderOptions = (answerOption: string) => {
    switch (answerOption) {
      case "Option A":
        return "A";
      case "Option B":
        return "B";
      case "Option C":
        return "C";
      case "Option D":
        return "D";
    }
  };

  const formAnswerSet = (
    answerItem: FormAnswerSet | string,
    uniqueQuestionId: string,
    questionType: string
  ) => {
    if (questionType === "MultipleChoice" && typeof answerItem === "object") {
      let tempAnswerObj = {
        module: moduleName,
        payload: {
          qId: uniqueQuestionId,
          userAnswer: answerItem?.answerPlaceHolder,
          userOption: answerItem?.answerOption,
        },
      };
      dispatch(setAnswers(tempAnswerObj));
    }
    if (questionType === "ShortForm" && typeof answerItem === "string") {
      let tempAnswerObj2 = {
        module: moduleName,
        payload: {
          qId: uniqueQuestionId,
          userAnswer: answerItem,
          userOption: "",
        },
      };
      dispatch(setAnswers(tempAnswerObj2));
    }
  };

  const renderInputFieldValue = (qItem: any) => {
    let matchedQuestion = memoizedUserAnswers.find(
      (item) => item.qId === qItem["_id"]
    );
    return matchedQuestion?.userAnswer ? matchedQuestion?.userAnswer : "";
  };

  const questionDescription = () => {
    return (
      <div className="w-1/2 ">
        {questionsList &&
        questionsList.length > 0 &&
        questionsList[count].description !==
          "student produced response directions" ? (
          <p>
            <RenderText
              text={questionsList[count].description}
              imageDetails={questionsList[count]}
              isOptions={false}
            />
          </p>
        ) : (
          <div>
            <MathsModuleDirections />
          </div>
        )}
      </div>
    );
  };

  const questionContent = () => {
    return (
      <div className="ml-4 w-1/2">
        {questionsList && questionsList.length > 0 && (
          <div className="flex">
            <div className="border rounded-full w-30 -mt-2 px-3 mx-1 h-10 bg-black flex items-center justify-center text-white font-semibold">
              {count + 1}
            </div>
            <p>
              <RenderText
                text={questionsList[count].processedQuestion}
                imageDetails={questionsList[count]}
                isOptions={false}
              />
            </p>
          </div>
        )}
        <div>
          {questionsList[count].answers.length > 0 ? (
            questionsList[count].answers?.map((answerItem: Answer, index) => {
              let matchedQuestion = memoizedUserAnswers.find(
                (item) => item.qId === questionsList[count]["_id"]
              );
              let selectedOption = questionsList[count].answers.find(
                (itm) => itm.answerOption === matchedQuestion?.userOption
              );
              let isSelected =
                answerItem.answerOption === selectedOption?.answerOption;

              return (
                <button
                  className={`border-2 min-w-96 flex rounded-md ${
                    isSelected ? "border-[#007aff]" : "border-[#e4e4e4]"
                  }  my-6  px-1 cursor-pointer`}
                  onClick={() =>
                    formAnswerSet(
                      answerItem,
                      questionsList[count]["_id"],
                      "MultipleChoice"
                    )
                  }
                  key={answerItem.answerOption}
                >
                  <div
                    className={`w-11 h-10 border m-2 rounded-full flex justify-center items-center ${
                      isSelected
                        ? "bg-[#007aff] text-white"
                        : "bg-slate-100 text-black"
                    }`}
                  >
                    {renderOptions(answerItem.answerOption)}
                  </div>
                  <div className="flex justify-center items-center my-auto">
                    <p>
                      <p>
                        <RenderText
                          text={answerItem.answerPlaceHolder}
                          imageDetails={questionsList[count]}
                          isOptions={true}
                        />
                      </p>
                    </p>
                  </div>
                </button>
              );
            })
          ) : (
            <div className="m-4 ml-4">
              <input
                type="text"
                placeholder="Your Answer.."
                className="border mx-2 border-[#e4e4e4] p-2 mt-2 rounded-md"
                value={renderInputFieldValue(questionsList[count])}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  formAnswerSet(
                    e.target.value,
                    questionsList[count]["_id"],
                    "ShortForm"
                  )
                }
              />
            </div>
          )}
        </div>
        <div></div>
      </div>
    );
  };
  function renderAnswersOrInput() {
    return (
      <div>
        {questionsList &&
        questionsList.length > 0 &&
        questionsList[count].answers.length > 0 ? (
          questionsList[count].answers.map((answerItem: Answer, index) => {
            let matchedQuestion = memoizedUserAnswers.find(
              (item) => item.qId === questionsList[count]["_id"]
            );
            let selectedOption = questionsList[count].answers.find(
              (itm) => itm.answerPlaceHolder === matchedQuestion?.userAnswer
            );
            let isSelected =
              answerItem.answerOption === selectedOption?.answerOption;
            return (
              <button
                className={`border-2 min-w-96 flex rounded-md ${
                  isSelected ? "border-[#007aff]" : "border-[#e4e4e4]"
                }  my-6  px-1 cursor-pointer`}
                onClick={() =>
                  formAnswerSet(
                    answerItem,
                    questionsList[count]["_id"],
                    "MultipleChoice"
                  )
                }
                key={answerItem.answerOption}
              >
                <div
                  className={`w-11 h-10 border m-2 rounded-full flex justify-center items-center ${
                    isSelected ? "bg-[#007aff]" : "bg-slate-100 text-black"
                  } `}
                >
                  {renderOptions(answerItem.answerOption)}
                </div>
                <p className="flex justify-center  items-center my-auto">
                  <p>
                    <RenderText
                      text={answerItem.answerPlaceHolder}
                      imageDetails={questionsList[count]}
                      isOptions={true}
                    />
                  </p>
                </p>
              </button>
            );
          })
        ) : (
          <div className="m-4 ml-4">
            {questionsList && questionsList.length > 0 && (
              <input
                type="text"
                placeholder="Your Answer.."
                className="border mx-2 border-[#e4e4e4] p-2 mt-2 rounded-md"
                value={renderInputFieldValue(questionsList[count])}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  formAnswerSet(
                    e.target.value,
                    questionsList[count]["_id"],
                    "ShortForm"
                  )
                }
              />
            )}
          </div>
        )}
      </div>
    );
  }
  const renderingQuestions = () => {
    return (
      <div className="p-2 mx-6 pt-10 text-lg leading-7 overflow-y-auto h-[100%] ">
        {questionsList &&
        questionsList.length > 0 &&
        questionsList[count].description ? (
          <div className="w-full flex ">
            {questionDescription()}
            {questionContent()}
          </div>
        ) : (
          <div className="flex justify-center items-center flex-col">
            {questionsList && questionsList.length > 0 && (
              <div className="flex ">
                <div className="border rounded-full w-10 -mt-2 mx-1 h-10 px-3 bg-black flex items-center justify-center text-white font-semibold">
                  {count + 1}
                </div>
                <p>
                  <RenderText
                    text={questionsList[count].processedQuestion}
                    imageDetails={questionsList[count]}
                  />
                </p>
              </div>
            )}
            {renderAnswersOrInput()}
          </div>
        )}
      </div>
    );
  };

  const handleOpenPopup = () => {
    if (!isPopUp) dispatch(setIsPopUp(true));
  };
  const closePopUp = useCallback(() => {
    if (isPopUp) dispatch(setIsPopUp(false));
  }, [isPopUp]);

  const openCalculator = () => {
    const url = "https://www.desmos.com/calculator"; // URL of the calculator
    const name = "CalculatorWindow";
    const specs = "width=400,height=500,top=100,left=100,noopener"; // Customize the size and position

    if (url.startsWith("https://www.desmos.com")) {
      // Validate the URL
      window.open(url, name, specs);
    } else {
      console.error("Invalid URL");
    }
  };

  const openReferences = () => {
    const newPath = "/references"; // Change this to the desired path
    const newUrl = `${window.location.origin}${newPath}`;

    // Define the window specifications
    const name = "ReferenceWindow";
    const specs = "width=400,height=500,top=100,left=100"; // Customize the size and position

    // Open the new window
    window.open(newUrl, name, `${specs},noopener,noreferrer`);
  };

  const openInstructions = () => {
    switch (moduleName) {
      case "writingModuleOneAnswers":
      case "writingModuleTwoAnswers":
        dispatch(openDialog("writingInstructions"));
        break;
      case "mathsModuleOneAnswers":
      case "mathsModuleTwoAnswers":
        dispatch(openDialog("mathsInstructions"));
        break;
    }
  };
  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      event.preventDefault();
      setItem("reload", "true");
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    if (getItem("reload")) {
      removeItem("reload");
      dispatch(closeDialog());
      navigate("/practices");
    } else {
      removeItem("reload");
      dispatch(closeDialog());
    }
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      dispatch(closeDialog());
    };
  }, [navigate]);

  return (
    <div className="flex flex-col h-screen">
      <div className="h-[10%] border-2 mx-6 rounded-b-3xl border-[#e4e4e4] flex pt-6 px-12 justify-between">
        <div className=" flex flex-col">
          <div>{heading()}</div>
          <button className="flex cursor-pointer" onClick={openInstructions}>
            Directions{" "}
            <div className="">
              <MdKeyboardArrowDown />
            </div>
          </button>
        </div>

        <div className="text-2xl font-extrabold items-center">
          <Timer />
        </div>

        <div className="flex">
          <div
            className={`pr-4 flex flex-col items-center gap-2 cursor-pointer ${
              moduleName === "writingModuleOneAnswers" ||
              moduleName === "writingModuleTwoAnswers"
                ? "hidden"
                : "flex"
            }`}
          >
            <FaCalculator size={30} onClick={openCalculator} />
            <div className="font-semibold">Calculator</div>
          </div>
          <div
            className={`pr-4 flex flex-col cursor-pointer ${
              moduleName === "writingModuleOneAnswers" ||
              moduleName === "writingModuleTwoAnswers"
                ? "hidden"
                : "flex"
            }`}
          >
            <button
              onClick={openReferences}
              style={{
                border: "none",
                background: "none",
                padding: 0,
                cursor: "pointer",
              }}
              className="flex items-center flex-col"
            >
              <img
                src={referenceImage}
                alt="reference"
                className="h-1/2 flex justify-center"
              />
              <div className="font-semibold">Reference</div>
            </button>
          </div>
          <button className="flex items-center" onClick={saveExitDialog}>
            {exit()}
          </button>
        </div>
      </div>

      <div className="flex-grow pb-20 overflow-hidden">
        {count === questionsList.length ? (
          <div className="w-1/2 mx-auto my-4">
            <div className="flex flex-col text-[#333] gap-4">
              <div className=" text-2xl text-center">Check Your Work</div>
              <div className="flex flex-col gap-2 text-base mx-auto my-2">
                <p>
                  On test day, you won't be able to move on to the next module
                  until time expires.
                </p>
                <p>
                  For these practice tests, you can click{" "}
                  <span className="font-semibold">Next</span> when you're ready
                  to move on.
                </p>
              </div>
              {/* <div className="font-semibold mx-auto text-xl">
              {heading()}
            </div> */}
            </div>
            <QuestionsOverView
              questionsList={questionsList}
              userAnswers={userAnswers}
              count={count}
              navigation={false}
              heading={heading}
            />
          </div>
        ) : (
          renderingQuestions()
        )}
      </div>

      <div
        style={{ width: sidebarCollapse ? "calc(100% - 224px)" : "calc(100% - 56px)" }}
        className="h-[10%] border-y-2 bg-white fixed bottom-0 border-[#e4e4e4] flex justify-between n items-center px-6"
      >
        <div className="text-sm">{textName()}</div>
        <button
          className={`w-[15%] font-semibold relative cursor-pointer text-sm ${
            count === questionsList.length
              ? "hidden"
              : "flex justify-center items-center"
          }  border rounded-full  border-[#e4e4e4] py-4`}
          onClick={() => handleOpenPopup()}
        >
          Questions {count + 1} of {questionsList?.length}
          <span className="ml-2">
            <MdKeyboardArrowUp />
          </span>
          <PopUp
            onClose={closePopUp}
            questionsList={questionsList}
            userAnswers={memoizedUserAnswers}
            heading={heading}
            count={count}
            isPopUp={isPopUp}
          />
        </button>
        <div className="flex">
          {count !== 0 && (
            <div>
              <CustomButton
                name="Back"
                onClickHandler={() => dispatch(setCount("decrement"))}
                font="font-bold"
                width="w-[6rem] text-sm"
                py="py-2"
                round="full"
                color="primaryColor"
                bg="bg-white"
                border="border-2 border-primaryColor"
              />
            </div>
          )}
          <div className="rounded-full mx-2">{next()}</div>
        </div>
      </div>
    </div>
  );
};

export default CommonTestModuleLayout;
