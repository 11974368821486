import React, { useEffect, useState ,Fragment, useRef} from "react";
import { changeCurrentModuleName, setCount, setCurrentTime, setOverallResultSlice } from "../redux/Slices/startFullTestSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectFullTestState } from "../redux/Selectors/selectorsUse";
import { useNavigate, useParams } from "react-router-dom";
import { Tab } from "@headlessui/react";
import ResultsTableContent from "../components/ReUsableComponents/ResultsTableContent";
import ErrorPage from "./ErrorPage";
import Loader from "../components/ReUsableComponents/Loader";
import { useEndTestMutation, useSaveAndExitTestMutation, useScaledScoresMutation } from "../redux/API/StartTest/startTestApi";
import LogoutHeader from "../components/ReUsableComponents/LogoutHeader";

const ModuleScoreCard = ({ module }: any) => {
  return (
    <div className="w-1/2 bg-[#f5f5f8] flex flex-col font-semibold text-[#202020] rounded-lg py-4 px-6">
      <div className="flex text-lg justify-between">
        <div>{module.name}</div>
        <div>
          <span className="text-blue-500">{module.correctAnswers}</span>
          <span className="text-blue-500">/{module.totalAnswers}</span>
        </div>
      </div>
      <div className="px-6 py-3">
        <ul>
          <li>
            <span>Module 1</span>
            <span className="text-blue-500 pl-4">
              {module.module1CorrectAnswers}
            </span>{" "}
            <span className="text-blue-500">/{module.module1TotalScore}</span>
          </li>
          <li className="py-2">
            <span>Module 2</span>
            <span className="text-blue-500 pl-4">
              {module.module2CorrectAnswers}
            </span>{" "}
            <span className="text-blue-500">/{module.module2TotalScore}</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

const ExamCompletedCard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { loadingSlice } = useSelector(selectFullTestState);
  const[saveAndExitTest,{ isSuccess: isSaveSuccess}]=useSaveAndExitTestMutation({});
  const [scaledScores] =useScaledScoresMutation({})
  const [endTest,{ isSuccess:  isEndSuccess}]= useEndTestMutation()
  const { isLoading, isError } = loadingSlice;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [overallResults, setOverallResults] = useState({
    readingModuleOneOverallResult: [],
    readingModuleTwoOverallResult: [],
    mathsModuleOneOverallResult: [],
    mathsModuleTwoOverallResult: [],
    readingModuleOneCorrectAnswers: [],
    readingModuleTwoCorrectAnswers: [],
    mathsModuleOneCorrectAnswers: [],
    mathsModuleTwoCorrectAnswers: [],
    allQuestionsTableData: [],
    inCorrectQuestionsTableData: [],
    overallFinaleScaledScore:0,
    overallReadingScaledScore:0,
    overallMathScaledScore:0
  });
  const { startTestSlice, resultSlice } = useSelector(selectFullTestState);
  const {
    readingModuleOneQuestions,
    readingModuleTwoQuestions,
    mathModuleOneQuestions,
    mathModuleTwoQuestions,
    userTestId,
    testId,
    currentModuleName,
    count,
    currentTime,
    completedTestData,
    readingAndWritingModuleOneSpentTime,
    readingAndWritingModuleTwoSpentTime,
    mathModuleOneSpentTime,
    mathModuleTwoSpentTime,
  } = startTestSlice;
  const {
    writingModuleOneAnswers,
    writingModuleTwoAnswers,
    mathsModuleOneAnswers,
    mathsModuleTwoAnswers,
  } = resultSlice;

  const modulesData = [
    {
      id: 1,
      name: "Reading and Writing",
      correctAnswers:
        overallResults.readingModuleOneCorrectAnswers.length +
        overallResults.readingModuleTwoCorrectAnswers.length,
      totalAnswers:
        overallResults?.readingModuleOneOverallResult.length +
          overallResults?.readingModuleTwoOverallResult.length || 54,
      module1CorrectAnswers:
        overallResults.readingModuleOneCorrectAnswers.length,
      module2CorrectAnswers:
        overallResults.readingModuleTwoCorrectAnswers.length,
      module1TotalScore: overallResults?.readingModuleOneOverallResult.length,
      module2TotalScore: overallResults?.readingModuleTwoOverallResult.length,
    },
    {
      id: 2,
      name: "Math",
      correctAnswers:
        overallResults?.mathsModuleOneCorrectAnswers.length +
        overallResults?.mathsModuleTwoCorrectAnswers.length,
      totalAnswers:
        overallResults?.mathsModuleOneOverallResult.length +
          overallResults?.mathsModuleTwoOverallResult.length || 44,
      module1CorrectAnswers:
        overallResults?.mathsModuleOneCorrectAnswers.length,
      module2CorrectAnswers:
        overallResults?.mathsModuleTwoCorrectAnswers.length,
      module1TotalScore: overallResults?.mathsModuleOneOverallResult.length,
      module2TotalScore: overallResults?.mathsModuleTwoOverallResult.length,
    },
  ];

  const modulesDataFinaleScore = [
    {
      id: 1,
      name: "Reading and Writing",
      correctAnswers:
        overallResults?.overallReadingScaledScore,
      totalAnswers:
        800,
      module1CorrectAnswers:
        overallResults.readingModuleOneCorrectAnswers.length,
      module2CorrectAnswers:
        overallResults.readingModuleTwoCorrectAnswers.length,
      module1TotalScore: overallResults?.readingModuleOneOverallResult.length,
      module2TotalScore: overallResults?.readingModuleTwoOverallResult.length,
    },
    {
      id: 2,
      name: "Math",
      correctAnswers:
        overallResults?.overallMathScaledScore,
      totalAnswers:
        800,
      module1CorrectAnswers:
        overallResults?.mathsModuleOneCorrectAnswers.length,
      module2CorrectAnswers:
        overallResults?.mathsModuleTwoCorrectAnswers.length,
      module1TotalScore: overallResults?.mathsModuleOneOverallResult.length,
      module2TotalScore: overallResults?.mathsModuleTwoOverallResult.length,
    },
  ];
  const transformString = (input: any) => {

    if(typeof(input) === 'string'){
 // Convert to lowercase
 let result = input?.toLowerCase();

 // Remove spaces
 result = result?.replace(/\s/g, "");

 // Remove commas
 result = result?.replace(/,/g, "");

 // Remove periods (dots)
 result = result?.replace(/\./g, "");

 return result;
    }
   
  };

const caluculateOverallResult = (questions: any, answers: any) => {
  return questions.map((question: any) => {
    const matchedAnswer = answers.find((answer: any) => answer.qId === question._id);

    if (!matchedAnswer || Object.keys(matchedAnswer).length === 0) {
      return {
        ...question,
        isAttempted: false,
        isCorrect: false,
        userAnswer: "",
        userOption: "",
      };
    }

    const userAnswer = matchedAnswer.userAnswer || "";
    const transformedUserAnswer = transformString(userAnswer);
    const transformedExactAnswer = transformString(question.answerValue);

    if (question.answers.length > 0) {
      const correctOption = question.answers.find((item: any) => item.isCorrectAnswer);
      const transformedCorrectOption = transformString(correctOption.answerOption || "");
      const transformedUserOption = transformString(matchedAnswer.userOption || "");
     if (transformedUserOption === transformedCorrectOption) {
        return {
          ...question,
          isAttempted: true,
          isCorrect: true,
          userAnswer,
          userOption: matchedAnswer.userOption || "",
        };
      }
    } else if (transformedUserAnswer === transformedExactAnswer) {
      return {
        ...question,
        isAttempted: true,
        isCorrect: true,
        userAnswer,
        userOption: matchedAnswer.userOption || "",
      };
    }

    return {
      ...question,
      isAttempted: true,
      isCorrect: false,
      userAnswer,
      userOption: matchedAnswer.userOption || "",
    };
  });
};

  const caluculateCorrectAnswers = (answers: any) => {
    return answers.filter((item: any) => item?.isCorrect);
  };
  const caluculateResultsTableData = (data: any) => {
    let result: any = [];

    const caluculateUserAnswer = (itm: any) => {
      if (itm?.answers.length > 0) {
        return itm?.userOption ? itm?.userOption : "Omitted";
      } else {
        return itm?.userAnswer ? itm?.userAnswer : "Omitted";
      }
    };
    const sectionName = (section: any) => {
      if (section === "ReadingAndWriting") {
        return "Reading and Writing";
      } else {
        return "Math";
      }
    };
console.log(data,"DATA")
    data.forEach((item: any) => {
      let content: any = {
        section: "",
        no: "",
        questionType: "",
        correctAnswer: "",
        yourChoice: "",
        explaination: "",
        isCorrect: false,
        _id:""
      };
      content["section"] =
        sectionName(item?.sectionName) + " : " + item?.moduleName;
      content["no"] = item?.qIdUi;
      content["questionType"] = item.answers.length > 0 ? "Multiple Choice" : "Short Answer";
      content["correctAnswer"] =item?.exactAnswer ? item.exactAnswer : "";
      content["yourChoice"] = caluculateUserAnswer(item);
      content["explaination"] = item?.explaination ? item.explaination : "";
      content["isCorrect"] = item?.isCorrect || false;
      content["_id"] = item?.["_id"]
      result.push(content);
    });
    return result;
  };
  const hasCalculatedResults = useRef(false);
  const caluculateResults = async() => {
    if (hasCalculatedResults.current) return;
    const readingModuleOneOverallResult = caluculateOverallResult(
      readingModuleOneQuestions,
      writingModuleOneAnswers
    );
    const readingModuleTwoOverallResult = caluculateOverallResult(
      readingModuleTwoQuestions,
      writingModuleTwoAnswers
    );
    const mathsModuleOneOverallResult = caluculateOverallResult(
      mathModuleOneQuestions,
      mathsModuleOneAnswers
    );
    const mathsModuleTwoOverallResult = caluculateOverallResult(
      mathModuleTwoQuestions,
      mathsModuleTwoAnswers
    );
    let combinedResults = [
      ...readingModuleOneOverallResult,
      ...readingModuleTwoOverallResult,
      ...mathsModuleOneOverallResult,
      ...mathsModuleTwoOverallResult,
    ];
    let resultsTableData = caluculateResultsTableData(combinedResults);
    let incorrectResultsTableData = resultsTableData?.filter(
      (item: any) => !item?.isCorrect
    );
    let readingModule1CorrectAnswers = caluculateCorrectAnswers(
      readingModuleOneOverallResult
    )
    let readingModule2CorrectAnswers = caluculateCorrectAnswers(
      readingModuleTwoOverallResult
    )
    let mathModule1CorrectAnswers = caluculateCorrectAnswers(
      mathsModuleOneOverallResult
    )
    let mathModule2CorrectAnswers = caluculateCorrectAnswers(
      mathsModuleTwoOverallResult
    )  
    let payLoad = {
      englishQuestionsAnswered:readingModule1CorrectAnswers?.length+readingModule2CorrectAnswers?.length,
      timeSpentOnEnglishQuestions:readingAndWritingModuleOneSpentTime+readingAndWritingModuleTwoSpentTime,
      mathQuestionsAnswered:mathModule1CorrectAnswers?.length + mathModule2CorrectAnswers?.length,
      timeSpentOnMathQuestions: mathModuleOneSpentTime+mathModuleTwoSpentTime,
    }
    hasCalculatedResults.current = true; 
    let scaledScoresData =await scaledScores(payLoad).unwrap();
    let {readingandwritingscaledscore,mathematicsscaledscore,totalscaledscore} =scaledScoresData.data
    const updatedResults: any = {
      readingModuleOneOverallResult: readingModuleOneOverallResult,
      readingModuleTwoOverallResult: readingModuleTwoOverallResult,
      mathsModuleOneOverallResult: mathsModuleOneOverallResult,
      mathsModuleTwoOverallResult: mathsModuleTwoOverallResult,
      readingModuleOneCorrectAnswers: readingModule1CorrectAnswers,
      readingModuleTwoCorrectAnswers: readingModule2CorrectAnswers,
      mathsModuleOneCorrectAnswers: mathModule1CorrectAnswers,
      mathsModuleTwoCorrectAnswers: mathModule2CorrectAnswers,
      allQuestionsTableData: resultsTableData,
      inCorrectQuestionsTableData: incorrectResultsTableData,
      overallFinaleScaledScore:totalscaledscore,
       overallReadingScaledScore:readingandwritingscaledscore,
       overallMathScaledScore:mathematicsscaledscore
    };
    let savePayload ={
      get_id: userTestId,
      testId: testId,
      userTestId: userTestId,
      questionData: {
        Mathematics:{
          Module1:mathModuleOneQuestions,
          Module2:mathModuleTwoQuestions
        },
        ReadingAndWriting:{
          Module1:readingModuleOneQuestions,
          Module2:readingModuleTwoQuestions
        } 
  
      },
      answerData: {
        Mathematics:{
          Module1:resultSlice.mathsModuleOneAnswers,
          Module2:resultSlice.mathsModuleTwoAnswers
        },
        ReadingAndWriting:{
          Module1:resultSlice.writingModuleOneAnswers,
          Module2:resultSlice.writingModuleTwoAnswers
        } 
  
      },
      additionalProps: {
        currentModuleName:currentModuleName,
        count:count,
        currentTime:currentTime,
        overallresult:updatedResults
       }, 
      testCompleted: true,
     
    }
    saveAndExitTest(savePayload);
    setOverallResults(updatedResults);
    setOverallResultSlice(updatedResults)
    localStorage.setItem("overallResults", JSON.stringify(updatedResults));
  };
  const lengthOfArray = (...rest: any) => {
    return rest.flat().length;
  };

  const renderContent = (isError:any,isLoading:any) => {
      if(isError){
        return <ErrorPage />
      }
      if(isLoading){
        return <div className="flex justify-center items-center w-full h-screen">
        <Loader />
      </div>
      }
      return <div className=" px-6 w-full h-full m-2">
      <LogoutHeader />
      <div className="flex justify-start text-2xl font-semibold text-blue-500 py-4">
        {localStorage.getItem("currentTestName")}
      </div>
      <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
        <Tab.List className="flex gap-3">
          <Tab as={Fragment}>
            {({ selected }) => (
              <button
                className={`${
                  selected ? "bg-blue-500 " : "bg-[#d5d4d4] "
                } rounded-t-lg text-white font-semibold px-5 py-3`}
              >
                Raw Score
              </button>
            )}
          </Tab>
          <Tab as={Fragment}>
            {({ selected }) => (
              <button
                className={`${
                  selected ? "bg-blue-500 " : "bg-[#d5d4d4] "
                } rounded-t-lg text-white font-semibold px-5 py-3`}
              >
               Finale Score
              </button>
            )}
          </Tab>
        </Tab.List>
        <Tab.Panels className="border border-[#d5d4d4] rounded-b-lg">
          <Tab.Panel className="px-3 py-1">
          <div className="border font-semibold border-[#d5d4d4] rounded-lg p-4 flex text-lg justify-between my-2">
        <div>Raw Score</div>
        <div>
          <span className="text-blue-500">
            {lengthOfArray(
              overallResults.readingModuleOneCorrectAnswers,
              overallResults.readingModuleTwoCorrectAnswers,
              overallResults.mathsModuleOneCorrectAnswers,
              overallResults.mathsModuleTwoCorrectAnswers
            )}
          </span>{" "}
          <span className="text-blue-500">
            /
            {lengthOfArray(
              overallResults.readingModuleOneOverallResult,
              overallResults.readingModuleTwoOverallResult,
              overallResults.mathsModuleOneOverallResult,
              overallResults.mathsModuleTwoOverallResult
            )}
          </span>
        </div>
      </div>
      <div className="flex justify-between w-full my-2 gap-10">
        {modulesData.map((item: any) => (
          <ModuleScoreCard module={item} key={item?.id}/>
        ))}
      </div>
          </Tab.Panel>
          <Tab.Panel className="px-3 py-1">
          <div className="border font-semibold border-[#d5d4d4] rounded-lg p-4 flex text-lg justify-between my-2">
        <div>Finale Score</div>
        <div>
          <span className="text-blue-500">
            {overallResults?.overallFinaleScaledScore}
          </span>{" "}
          <span className="text-blue-500">
            /
            1600
          </span>
        </div>
      </div>
      <div className="text-red-600 font-semibold text-xs"> * The final score is AI-generated based on previous test scores and may differ slightly from the actual SAT score</div>
      <div className="flex justify-between w-full my-2 gap-10">
        {modulesDataFinaleScore.map((item: any) => (
          <ModuleScoreCard module={item} key={item?.id}/>
        ))}
      </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
      <div className="flex justify-center">
        <div className="  flex flex-col text-lg "></div>
      </div>
      <div className="my-2">
      <Tab.Group>
        <Tab.List className="flex gap-3">
          <Tab as={Fragment}>
            {({ selected }) => (
              <button
                className={`${
                  selected ? "bg-blue-500 " : "bg-[#d5d4d4] "
                } rounded-t-lg text-white font-semibold px-5 py-3`}
              >
                All Questions
              </button>
            )}
          </Tab>
          <Tab as={Fragment}>
            {({ selected }) => (
              <button
                className={`${
                  selected ? "bg-blue-500 " : "bg-[#d5d4d4] "
                } rounded-t-lg text-white font-semibold px-5 py-3`}
              >
                InCorrect Answers
              </button>
            )}
          </Tab>
        </Tab.List>
        <Tab.Panels className="border border-[#d5d4d4] rounded-b-lg">
          <Tab.Panel className="px-3 py-1">
            <ResultsTableContent
              data={overallResults.allQuestionsTableData}
            />
          </Tab.Panel>
          <Tab.Panel className="px-3 py-1">
            <ResultsTableContent
              data={overallResults.inCorrectQuestionsTableData}
            />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
      </div>
     

      <div className="flex justify-center my-4">
        <button
          className="text-white bg-primaryColor px-4 py-2 rounded-lg"
          onClick={() => navigate("/practices")}
        >
          Home
        </button>
      </div>
    </div>
  }

  useEffect(() => {
    const savedResults = localStorage.getItem("overallResults");
    const isScore = localStorage.getItem("isScoreCaluculated")
    if (savedResults) {
      setOverallResults(JSON.parse(savedResults));
    } else {
      dispatch(changeCurrentModuleName("writingModule1"));
      if(isScore){
        let currentTest = completedTestData.find((item:any) => item?.testId === id);
        let updatedResults:any= currentTest.additionalProps.overallresult
        localStorage.setItem("overallResults", JSON.stringify(updatedResults));
        setOverallResults(updatedResults)
      }else{
        caluculateResults();
      }
      
    }
  }, []);

  useEffect(() => {
    if (isSaveSuccess) {
      endTest(userTestId);
    }
  }, [isSaveSuccess, endTest, userTestId]);

  useEffect(() => {
    if (isEndSuccess) {
      dispatch(setCount(""));
      navigate(`/practices/results/${testId}`);
      dispatch(setCurrentTime(1920));
    }
  }, [isEndSuccess, dispatch, navigate, testId]);


  return (
    <div>
      {renderContent(isError,isLoading)}
    </div>
  );
};

export default ExamCompletedCard;
