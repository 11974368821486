import { useCallback, useEffect } from "react";
import CommonTestModuleLayout from "../ReUsableComponents/CommonTestModuleLayout";
import CustomButton from "../ReUsableComponents/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import {
  changeCurrentModuleName,
  setCount,
  setCurrentTime,
  setIsPopUp,
  setSpentTime,
  setTestCompleted,
} from "../../redux/Slices/startFullTestSlice";

import { selectFullTestState } from "../../redux/Selectors/selectorsUse";
import { openDialog } from "../../redux/Slices/dialogSlice";
import { RootState } from "../../redux/Store/store";
import { getItem } from "../../utils/token";

const WriteTestModuleOne = () => {
  const dispatch = useDispatch();
  const { startTestSlice, resultSlice } = useSelector(selectFullTestState);
  const saveHandler = () => {
    dispatch(openDialog("writingInstructions"));
  };

  const { readingModuleOneQuestions, count } = startTestSlice;
  const currentTime = useSelector(
    (state: RootState) => state.root.startFullTest.currentTime
  );
  const { writingModuleOneAnswers } = resultSlice;

  const heading = useCallback(() => {
    return (
      <div className="flex flex-col">
        <div className="text-lg font-semibold pb-2">
          Reading and Writing: Module 1
        </div>
        {/* <div className="cursor-pointer">Directions </div> */}
      </div>
    );
  }, []);
  const timer = () => {
    return <div>29:25</div>;
  };
  const exit = () => {
    return (
      <div>
        <CustomButton
          name="Save & Exit"
          onClickHandler={saveHandler}
          bg="bg-white"
          color="[#333]"
          font="font-bold"
          width="w-full"
          border="border-[#333]"
          py="py-2"
          round="full"
        />
      </div>
    );
  };
  const children = () => {
    return (
      <div className="flex justify-center items-center">
        <div>Writing Test Module One Children Component </div>
        <div></div>
      </div>
    );
  };
  const textName = () => {
    return <div className="font-semibold"> {getItem("currentTestName")} </div>;
  };

  const nexxtClickHandler = () => {
    dispatch(setIsPopUp(false));
    if (count > readingModuleOneQuestions.length - 1) {
      dispatch(
        setSpentTime({
          field: "readingAndWritingModuleOneSpentTime",
          value: +currentTime,
        })
      );
      dispatch(changeCurrentModuleName("writingModule2"));
      dispatch(setCurrentTime(1920));
      dispatch(setCount(""));
    } else {
      dispatch(setCount("Increment"));
    }
  };

  const next = () => {
    return (
      <div>
        <CustomButton
          name="Next"
          onClickHandler={nexxtClickHandler}
          font="font-bold"
          width="w-[6rem] text-sm"
          py="py-2"
          round="full"
          // hover="hover:bg-primaryColor"
        />
      </div>
    );
  };

  useEffect(() => {
    dispatch(changeCurrentModuleName("writingModule1"));
    dispatch(openDialog("writingInstructions"));
    dispatch(setTestCompleted(false));
  }, []);

  useEffect(() => {
    if (currentTime <= 0) {
      dispatch(changeCurrentModuleName("writingModule2"));
      dispatch(setCurrentTime(1920));
      dispatch(setCount(""));
    }
  }, [currentTime]);
  return (
    <CommonTestModuleLayout
      heading={heading}
      timer={timer}
      exit={exit}
      textName={textName}
      next={next}
      questionsList={readingModuleOneQuestions}
      moduleName={"writingModuleOneAnswers"}
      userAnswers={writingModuleOneAnswers}
    >
      {children}
    </CommonTestModuleLayout>
  );
};

export default WriteTestModuleOne;
