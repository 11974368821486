import api from '../createApi'

export const loginApi = api.injectEndpoints({
    endpoints: (builder) => ({
        signup :builder.mutation({
            query:(payload)=>({
               url:'/auth/signup',
               method:'POST',
               body:payload,
            })
        }),
        signIn:builder.mutation({
            query:(payload)=>({
                url:'/auth/login',
                method:"POST",
                body:payload
            })
        }),
        ssoLogin:builder.query({
            query:(ssoToken)=>({
                url:`/sso/login?token=${ssoToken}`,
                method : "GET"
            })
        })
    })})
    export const {useSignupMutation,useSignInMutation,useLazySsoLoginQuery} = loginApi