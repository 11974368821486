import { useState, useEffect } from 'react';
import SignIn from './SignIn';
import SignUp from './SignUp';
import LeftOverlayContent from './LeftOverlayContent';
import RightOverlayContent from './RightOverlayContent';
import { closeDialog } from '../../redux/Slices/dialogSlice';
import { useDispatch } from 'react-redux';
import { setItem } from '../../utils/token';

const LogIn = () => {
  const [isAnimated, setIsAnimated] = useState<boolean>(false);
  const path = window.location.pathname;
  const dispatch = useDispatch();

  useEffect(()=>{
    if(path === '/signupnew'){
      setIsAnimated(true);
    }
    dispatch(closeDialog());
  },[])
  useEffect(() => {
    setItem("token", "")
  },[])

  return (
    <div
      className="flex relative items-center justify-center h-screen bg-cover bg-center"
      style={{ backgroundImage: "url('./svgs/login_screen_bg.svg')" }}
    >
      <div className=" w-[85%] md:w-[85%] lg:w-[60%] py-[15px] md:py-[20px] bg-white relative overflow-hidden rounded-[20px] my-[70px] shadow-lg max-h-[95%]">
        <div
          id="signin"
          className={`bg-white absolute top-0 left-0 h-full w-full md:w-1/2 flex justify-center items-center md:transition-all md:duration-700 md:ease-in-out z-20 ${
            isAnimated ? 'md:translate-x-full opacity-0 z-[-1]' : ''
          }`}
        >
          <SignIn isAnimated={isAnimated} setIsAnimated={setIsAnimated} />
        </div>
        <div
          id="signup"
          className={`h-full w-full md:w-1/2 flex justify-center items-center md:transition-all md:duration-700 md:ease-in-out ${
            isAnimated ? 'md:translate-x-full opacity-100 z-50 animate-show' : 'opacity-0 z-50'
          }`}
        >
          <div className="h-full w-full flex justify-center items-center">
            <SignUp isAnimated={isAnimated} setIsAnimated={setIsAnimated}/>
          </div>
        </div>

        <div
          id="overlay-container"
          className={`hidden md:block absolute top-0 left-[50%] w-1/2 h-full overflow-hidden transition transition-transform duration-700 ease-in-out z-100 ${
            isAnimated ? '-translate-x-full z-100' : ''
          }`}
        >
          <div
            id="overlay"
            className={`relative -left-full h-full w-[200%] transform transition transition-transform duration-700 ease-in-out ${
              isAnimated ? 'translate-x-1/2' : 'translate-x-0'
            }`}
          >
            <div
              id="overlay-left"
              className={`w-[50%] bg-primaryColor h-full absolute flex justify-center items-center top-0 transform -translate-x-[50%] transition transition-transform duration-700 ease-in-out ${
                isAnimated ? 'translate-x-0 animate-show' : '-translate-x-[20%]'
              }`}
            >
              <LeftOverlayContent isAnimated={isAnimated} setIsAnimated={setIsAnimated} />
            </div>
            <div
              id="overlay-right"
              className={`w-[50%] bg-primaryColor h-full absolute flex justify-center items-center top-0 right-0 ${
                isAnimated ? 'translate-x-[10%] hidden' : 'translate-x-0'
              }`}
            >
              <RightOverlayContent isAnimated={isAnimated} setIsAnimated={setIsAnimated} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogIn;
