import { useEffect, useState } from "react";
import { selectFullTestState } from "../../redux/Selectors/selectorsUse";
import { useDispatch, useSelector } from "react-redux";
import { getItem } from "../../utils/token";
import RenderText from "./RenderText";
import { closeDialog } from "../../redux/Slices/dialogSlice";
import { IoCloseSharp } from "react-icons/io5";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import MathsModuleDirections from "./MathsModuleDirections";

const ResultsDialog = () => {
  const [question, setQuestion] = useState<any>({});
  const { startTestSlice } = useSelector(selectFullTestState);
  const dispatch = useDispatch();
  const { currentClickedQuestionId } = startTestSlice;
  const [overallResult, setOverallResult] = useState<any>({});

  const questionIndex = (currentModuleData:any,direction:any,currentQuestionId:any) => {
return currentModuleData.findIndex(
  (item: any) =>
    item.qIdUi ===
    (direction === "next"
      ? currentQuestionId + 1
      : currentQuestionId - 1)
);
  }

  const moveToPreviouse = (totalData:any,currentSectionsectionName:any,direction:any) =>{
    return totalData.filter(
      (item:any) =>
        item.sectionName === currentSectionsectionName &&
        item.moduleName === (direction === "next" ? "Module2" : "Module1")
    );
  }

  const navigateQuestion = (currentClickedQuestion:any,totalData:any,direction:any,currentSectionsectionName:any,sectionName: any, moduleName: any) => {
    let currentModuleData = totalData.filter(
      (item: any) =>
        item.sectionName === sectionName && item.moduleName === moduleName
    );
    let currentQuestionId = +currentClickedQuestion.qIdUi;
    let navigateQuestionIndex = questionIndex(currentModuleData,direction,currentQuestionId)
    if (navigateQuestionIndex !== -1) {
      setQuestion(currentModuleData[navigateQuestionIndex]);
    } else if (
      currentClickedQuestion.moduleName ===
      (direction === "next" ? "Module1" : "Module2")
    ) {
      let navigateQuestionFromPreviouse = moveToPreviouse(totalData,currentSectionsectionName,direction)
      setQuestion(
        direction === "next"
          ? navigateQuestionFromPreviouse[0]
          : navigateQuestionFromPreviouse[
              navigateQuestionFromPreviouse.length - 1
            ]
      );
    } else {
      let navigateQuestions = totalData.filter(
        (item:any) =>
          item.sectionName !== currentSectionsectionName &&
          item.moduleName === (direction === "next" ? "Module1" : "Module2")
      );
      setQuestion(
        direction === "next"
          ? navigateQuestions[0]
          : navigateQuestions[navigateQuestions.length - 1]
      );
    }
  };

  const navigateToQuestions = (direction: any) => {
    try {
      let currentClickedQuestion = JSON.parse(JSON.stringify(question));
      let currentSectionsectionName = currentClickedQuestion.sectionName;

      let {
        mathsModuleOneOverallResult,
        mathsModuleTwoOverallResult,
        readingModuleOneOverallResult,
        readingModuleTwoOverallResult,
      } = overallResult;
      let totalData = [
        ...readingModuleOneOverallResult,
        ...readingModuleTwoOverallResult,
        ...mathsModuleOneOverallResult,
        ...mathsModuleTwoOverallResult,
      ];


      navigateQuestion(currentClickedQuestion,
        totalData,
        direction,
        currentSectionsectionName,
        currentClickedQuestion.sectionName,
        currentClickedQuestion.moduleName
      );
    } catch (e) {
      console.log(e, "ERROR");
    }
  };

  const PreviouseClickHandler = () => navigateToQuestions("Previouse");
  const nextClickHandler = () => navigateToQuestions("next");

  const getClassName = (answerItem: any) => {
    let baseClassName = `border-2 min-w-96 flex rounded-md  my-6  px-2 py-1 items-center  cursor-pointer`;
    let ringClassName = "";
    let borderClassName = "";
    if (
      question.userOption === answerItem.answerOption &&
      question.userOption === question.exactAnswer
    ) {
      ringClassName = "ring ring-primaryColor ring-offset-2";
      borderClassName = "border-4 border-[#37aa64]";
    } else if (question.userOption === answerItem.answerOption) {
      ringClassName = "ring ring-red-600 ring-offset-2";
      borderClassName = "border-[#e4e4e4]";
    } else if (answerItem.answerOption === question.exactAnswer) {
      ringClassName = "ring ring-primaryColor ring-offset-1";
      borderClassName = "border-[#e4e4e4]";
    }
    return `${baseClassName} ${ringClassName} ${borderClassName}`;
  };

  const getAnswerText = (question:any) => {
    if (question.userOption) {
      return question.userOption;
    } else if (question.userAnswer) {
      return question.userAnswer;
    } else {
      return "Not Answered";
    }
  };

  useEffect(() => {
    let data: any = getItem("overallResults");
    setOverallResult(JSON.parse(data));
  }, []);
  useEffect(() => {
    if (overallResult && Object.keys(overallResult).length > 0) {
      let {
        mathsModuleOneOverallResult,
        mathsModuleTwoOverallResult,
        readingModuleOneOverallResult,
        readingModuleTwoOverallResult,
      } = overallResult;
      let totalData = [
        ...readingModuleOneOverallResult,
        ...readingModuleTwoOverallResult,
        ...mathsModuleOneOverallResult,
        ...mathsModuleTwoOverallResult,
      ];
      let currentClickedQuestion = totalData.find(
        (item: any) => item["_id"] === currentClickedQuestionId
      );
      setQuestion(currentClickedQuestion);
    }
  }, [overallResult]);

  return (
    <div className="mx-4 my-2 overflow-y-auto h-[80vh]">
      {Object.keys(question).length > 0 ? (
        <>
          <header className="flex w-full border-b-2 border-[#e4e4e4] pb-2">
            <div className="flex flex-col w-1/2  font-bold gap-1">
              <p className="text-[#202020] text-xl ">
                {question?.sectionId === "65f412858414794a12440d1a"
                  ? "Reading And Writing"
                  : "Math"}{" "}
                : {question?.moduleName} - Question {question?.qIdUi}
              </p>
              <p className="text-primaryColor text-lg">
                QuestionType: {question?.questionContentName}
              </p>
            </div>
            <div className="flex gap-5 w-1/2 text-lg  items-center justify-end">
              <button
                className="px-6 py-2 bg-primaryColor flex items-center text-white font-bold tracking-wider rounded-xl"
                onClick={PreviouseClickHandler}
              >
                <FaAngleLeft />
                Prev
              </button>
              <button
                className="px-6 py-2 bg-primaryColor text-white flex items-center font-bold tracking-wider rounded-xl"
                onClick={nextClickHandler}
              >
                Next <FaAngleRight />
              </button>
              <button
                onClick={() => dispatch(closeDialog())}
                className="flex border-2 border-[#424242] rounded-lg p-2 mr-3 items-center"
              >
                <IoCloseSharp />
              </button>
            </div>
          </header>
          <body className="w-full flex">
            <section className="w-1/2 border-r-2 border-[#e4e4e4] flex flex-col  text-lg leading-7 px-2 py-3 ">
              <p>
                {question?.description !==
                "student produced response directions" ? (
                  <p>
                    <RenderText
                      text={question?.description}
                      imageDetails={question}
                      isOptions={false}
                    />
                  </p>
                ) : (
                  <div>
                    <MathsModuleDirections />
                  </div>
                )}

              </p>
              <p>
                <RenderText
                  text={question?.processedQuestion}
                  imageDetails={question}
                  isOptions={false}
                />
              </p>
              <p>
                {question.answers.length > 0 &&
                  question.answers.map((answerItem: any) => (
                    <div
                      className={getClassName(answerItem)}
                      key={answerItem.answerOption}
                    >
                      <p className="w-20 h-9 rounded-full bg-slate-300  flex items-center justify-center mr-1">
                        {answerItem.answerOption.replace("Option", "").trim()}
                      </p>
                      <p>
                        <RenderText
                          text={answerItem.answerPlaceHolder}
                          imageDetails={question}
                          isOptions={true}
                        />
                      </p>
                    </div>
                  ))}
                   {question.answers.length > 0 && (
                <div className="flex justify-end items-center">
                  <span
                    className={`w-4 h-4  rounded-full  ${
                      question?.isCorrect ? "bg-green-700 " : "bg-[#d50f00]"
                    }`}
                  ></span>
                  <p
                    className={`font-bold pl-2  ${
                      question?.isCorrect ? "text-green-700 " : "text-[#d50f00]"
                    } pr-8`}
                  >
                    {" "}
                    Your Answer
                  </p>{" "}
                  <span className="w-4 h-4 bg-primaryColor rounded-full"></span>
                  <p className="font-bold pl-2 text-primaryColor">
                    {" "}
                    Correct Answer
                  </p>
                </div>)}
              </p>
            </section>
            <section className="text-lg leading-7 px-6 py-3 w-1/2">
              <p className="font-semibold">Your answer</p>
              <p
                className={`ml-4 font-bold ${
                  question?.isCorrect ? "text-green-700 " : "text-[#d50f00]"
                }`}
              >
                {getAnswerText(question)}
              </p>
              <p className="font-semibold">Correct Answer</p>
              <p className="ml-4 text-primaryColor font-bold">
                {question?.exactAnswer}
              </p>
              <p className="font-semibold">Explaination</p>
              <p className="ml-4">
                <RenderText
                  text={question?.explanation}
                  imageDetails={question}
                  isOptions={false}
                />
              </p>
            </section>
          </body>
        </>
      ) : (
        <div>No Questions</div>
      )}
    </div>
  );
};

export default ResultsDialog;
