import { useEffect } from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import { useNavigate, useLocation } from "react-router-dom";
import { getItem, setItem } from "../utils/token";
import { RxDashboard } from "react-icons/rx";
import { TbNotebook } from "react-icons/tb";
import { FaRegQuestionCircle } from "react-icons/fa";
import { FaChartLine } from "react-icons/fa";
import { FaWallet } from "react-icons/fa";
import { setIsSidebarCollapse } from "../redux/Slices/startFullTestSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/Store/store";

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {sidebarCollapse} = useSelector((state:RootState) => state.root.startFullTest)
  const redirectToAnotherRoute = (route: any) => {
    navigate(route);
    setItem("activeMenu", route);
  };

  useEffect(() => {
    setItem("activeMenu", location.pathname);
    // navigate(location.pathname);
  }, [location.pathname]);

  const Menus = [
    {
      title: "Dashboard",
      src: <RxDashboard className="text-xl" />,
      active: <RxDashboard className="text-[blue] text-xl" />,
      redirectTo: "/dashboard",
      isVisible: true,
    },
    {
      title: "Practice Tests",
      src: <TbNotebook className="text-xl" />,
      active: <TbNotebook className="text-[blue] text-xl" />,
      redirectTo: "/practices",
      isVisible: true,
    },
    {
      title: "Question Bank",
      src: <FaRegQuestionCircle className="text-xl" />,
      active: <FaRegQuestionCircle className="text-[blue] text-xl" />,
      redirectTo: "/categories",
      isVisible: true,
    },
    {
      title: "Test Results",
      src: <FaChartLine className="text-xl" />,
      active: <FaChartLine className="text-[blue] text-xl" />,
      redirectTo: "/practices/results",
      isVisible: true,
    },
    {
      title: "Pricing",
      src: <FaWallet className="text-xl" />,
      active: <FaWallet className="text-[blue] text-xl" />,
      redirectTo: "/products",
      isVisible: true,
    },
  ];

  console.log(location.pathname, getItem("activeMenu"), "location678");

  return (
    <div className="flex border-r border-r-[#E3E5E9]">
      <div
        className={` ${
          sidebarCollapse ? "w-56" : "w-14 "
        } bg-white h-screen py-5 pt-8 relative duration-300`}
      >
        <div className={`${sidebarCollapse && "gap-x-4"} flex items-center px-4`}>
          <button
            className={`cursor-pointer bg-[#eaeaff] rounded-full p-1 ${
              !sidebarCollapse && "rotate-180"
            }`}
            onClick={() => dispatch(setIsSidebarCollapse(!sidebarCollapse))}
          >
            <RxHamburgerMenu />
          </button>
          {sidebarCollapse && (
            <h1
              className={`text-[blue] origin-left font-medium text-xl duration-200 ${
                !sidebarCollapse && "scale-0"
              }`}
            >
              S A T
            </h1>
          )}
        </div>
        <ul className="pt-6">
          {Menus.map(
            (Menu, index) =>
              Menu.isVisible && (
                <button
                  key={Menu.title}
                  className={`flex p-4 cursor-pointer text-gray-300 text-sm items-center ${
                    sidebarCollapse ? "gap-x-4" : "gap-x-0"
                  } ${index === 0 && "bg-light-white"}                
          `}
                  onClick={() => redirectToAnotherRoute(Menu.redirectTo)}
                >
                  <li className="flex items-center mt-2" title={Menu.title}>
                    {location.pathname === Menu.redirectTo
                      ? Menu.active
                      : Menu.src}

                    <button
                      className={`${!sidebarCollapse && "hidden"} ${
                        location.pathname === Menu.redirectTo
                          ? "text-[blue] font-medium"
                          : "text-gray-400"
                      } origin-left text-lg duration-200 ml-1 `}
                    >
                      {Menu.title}
                    </button>
                  </li>
                </button>
              )
          )}
        </ul>
      </div>
    </div>
  );
};
export default Sidebar;
