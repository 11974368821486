import api from '../createApi';

export const startTestApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllMockTests: builder.query({
      query: () => ({
        url: "/test/getfreetest",
        method: "GET",
      }),
    }),
    getProTests:builder.query({
      query: () => ({
        url: "/test/getprotest",
        method: "GET",
      })
    }),
    getPricingPlansData: builder.query({
      query: () => ({
        url: "/plan/getAll",
        method: "GET",
      })
    }),
    getStartTestData: builder.query({
      query: (testId) => ({
        url: `/test/starttest?testId=${testId}`,
        method: "GET",
      })
    }),
    getQuestionBankList: builder.query({
      query: () => ({
        url: "/questionbank/getall",
        method: "GET",
      })
    }),
    getQuestionInQuestionBank:builder.query({
      query: (questionBankId) => ({
        url:`/questionbank/getbyid?questionBankId=${questionBankId}`,
        method: "GET",
      })
    }),
    saveAndExitTest: builder.mutation({
      query: (payload) => ({
        url: "/test/savetest",
        method: "POST",
        body: payload,
      }),
    }),
    saveAndExitQuestionBank:builder.mutation({
      query: (payload) => ({
        url: "/questionbank/savequestionbank",
        method: "POST",
        body: payload,
      })
    }),
    endTest: builder.mutation({
      query: (testId) => ({
        url: `/test/endtest?userTestId=${testId}`,
        method: "PUT",
      }),
    }),
    getAllCompletedTest: builder.query({
      query: () => ({
        url: `/test/allusertests`,
        method: "GET",
      })
    }),
    scaledScores:builder.mutation({
      query:(payload) => ({
        url:`test/computingrawscore`,
        method: "POST",
        body: payload,
      })
     }),
    retrieveTest: builder.query({
      query: (testId) => ({
        url: `test/retrievetest?testId=${testId}`,
        method: "GET",
      }),
      
    
    }),
  })
});

export const { useGetAllMockTestsQuery, useLazyGetProTestsQuery,useLazyGetStartTestDataQuery, useSaveAndExitTestMutation, useEndTestMutation, useGetAllCompletedTestQuery, useLazyRetrieveTestQuery, useGetPricingPlansDataQuery, useGetQuestionBankListQuery ,useGetQuestionInQuestionBankQuery,useScaledScoresMutation,useSaveAndExitQuestionBankMutation} = startTestApi