import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { setItem } from "../utils/token";
import CambriLogo from "../assets/Images/cambri_logo.png"
import SatLogo from "../assets/Images/satIcon.png"
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { useLazySsoLoginQuery } from "../redux/API/Login/loginApi";

const RedirectionPage = () => {

  const navigate = useNavigate();
  const {isLogin,token} = useParams();
  const [ssoLogin] = useLazySsoLoginQuery();

  const handleSuccess = async (login : string,authToken:string) => {
    navigate("/practices");
    toast.success("Login Successful");
    setItem("isLogin", login);
    if (authToken) {
      setItem("token", authToken);
      const decoded: any = jwtDecode(authToken);
      setItem("userName", decoded?.name || '');
      const expiryTime = decoded.exp;
      if (expiryTime) {
        setItem("expiryTime", expiryTime.toString());
      }

      await ssoLogin(authToken);
    } 
  };

  useEffect(() => {
    if(isLogin && token){
      handleSuccess(isLogin,token);
    }
  },[isLogin,token]);

  return (

    <section className="flex justify-center items-center h-screen space-x-10">
      <img src={CambriLogo} alt="cambri-logo" className="w-32 h-32" />
      <div className="transform rotate-180">
        <div className="redirect-loading"></div>
      </div>
      <img src={SatLogo} alt="Sat-logo" className="w-32 h-32" />
    </section>
  );
};

export default RedirectionPage;
